<template>
  <div class="header-container">
    <div class="wrapper">
      <TheHeader />
    </div>
  </div>
  <main class="main wrapper">
    <slot></slot>
  </main>
  <div class="footer-container">
    <div class="wrapper footer">
      <TheFooter />
    </div>
  </div>
</template>

<script>
import TheHeader from "@/components/TheHeader";
import TheFooter from "@/components/TheFooter";

export default {
  name: "BaseLayout",
  components: {
    TheHeader,
    TheFooter,
  },
};
</script>

<style lang="scss" scoped></style>
