import axios from "./../../../node_modules/axios/";
import router from "./../../router/";

const state = {
  app: {
    clientid: "85252",
    clientSecret: "8b05f900658720841a2da742ecf2dda8de5475c0",
  },
  user: {
    access_token: null,
    refresh_token: null,
    expires_at: null,
    expires_in: null,
    token_type: null,
  },
  loggedIn: false,
};

const mutations = {
  setUser(state, payload) {
    if (payload) {
      console.log("Set User");
      state.user.access_token = payload.access_token;
      state.user.refresh_token = payload.refresh_token;
      state.user.expires_at = payload.expires_at;
      state.user.expires_in = payload.expires_in;
      state.user.token_type = payload.token_type;
      state.athlete = payload.athlete;

      localStorage.setItem("access_token", payload.access_token);
      localStorage.setItem("refresh_token", payload.refresh_token);
      localStorage.setItem("expires_at", payload.expires_at);
      localStorage.setItem("athlete", JSON.stringify(payload.athlete));

      state.loggedIn = true;

      router.push({ path: "/shoesoverview" });
    }
  },
  removeUser(state) {
    console.log("User logout");
    state.user = null;
    state.athlete = null;

    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("expires_at");
    localStorage.removeItem("athlete");

    state.loggedIn = false;

    router.push({ path: "/" });
  },
};

const actions = {
  getURLParams({ dispatch }) {
    // first check if we already have a access_token
    const currentDate = Math.floor(Date.now() / 1000);
    if (
      localStorage.getItem("access_token") &&
      Number(localStorage.getItem("expires_at")) > currentDate
    ) {
      console.log("already loggedIn");

      // refresh data in store with data from localstorage
      dispatch("updateUserAuth");
      dispatch("getGearFromLocalStorage");
    } else if (
      localStorage.getItem("access_token") &&
      Number(localStorage.getItem("expires_at") < currentDate)
    ) {
      console.log("accessToken found but expired");
      dispatch("updateUserAuth");
      dispatch("refreshToken");
      dispatch("getGearFromLocalStorage");
    } else {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const code = urlParams.get("code");
      window.history.replaceState(null, "", window.location.pathname);

      if (code !== null) {
        dispatch("getAuthToken", code);
      }
    }
  },
  getAuthCode(state) {
    window.location = `https://www.strava.com/oauth/authorize?client_id=${state.app.clientid}&redirect_uri=http://localhost:8081/&response_type=code&scope=read_all,activity:read_all,profile:read_all`;
  },
  getAuthToken({ commit, state }, code) {
    const url = `https://www.strava.com/oauth/token?client_id=${state.app.clientid}&code=${code}&client_secret=${state.app.clientSecret}&grant_type=authorization_code`;

    return axios
      .post(url)
      .then((response) => {
        //console.log(response);
        commit("setUser", response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  updateUserAuth(context) {
    console.log("Get UserInformation from localStorage an write it in state");
    const localAuthDO = {
      access_token: localStorage.getItem("access_token"),
      refresh_token: localStorage.getItem("refresh_token"),
      expires_at: localStorage.getItem("expires_at"),
      athlete: JSON.parse(localStorage.getItem("athlete")),
    };

    context.commit("setUser", localAuthDO);
  },
  getAthlete({ dispatch }) {
    // get athlete information
    console.log("get athlete data");
    const url = `https://www.strava.com/api/v3/athlete?&access_token=${state.auth.user.access_token}`;

    return axios
      .post(url)
      .then((response) => {
        localStorage.setItem("athlete", response.data);

        dispatch("updateUserAuth");
      })
      .catch((error) => {
        console.log(error);
      });
  },
  refreshToken({ dispatch, state }) {
    console.log("get a new Acces token");
    const url = `https://www.strava.com/oauth/token?client_id=${state.app.clientid}&client_secret=${state.app.clientSecret}&grant_type=refresh_token&refresh_token=${state.user.refresh_token}`;

    return axios
      .post(url)
      .then((response) => {
        localStorage.setItem("access_token", response.data.access_token);
        localStorage.setItem("refresh_token", response.data.refresh_token);
        localStorage.setItem("expires_at", response.data.expires_at);
        dispatch("updateUserAuth");
      })
      .catch((error) => {
        console.log(error);
      });
  },
  logout({ commit }) {
    commit("removeUser");
  },
};

const getters = {
  isUserLoggedIn(state) {
    return state.loggedIn;
  },
  userName(state) {
    return state.athlete.firstname;
  },
  userImage(state) {
    return state.athlete.profile_medium;
  },
};

const authModule = {
  state,
  mutations,
  actions,
  getters,
};

export default authModule;
