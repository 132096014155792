<template>
  <BaseLayout>
    <template #default>
      <div class="col-50 col-center">
        <h2>Privacy Policy</h2>

        <p>
          Hello on allyoucanrun, a free and non-commercial digital service. Your
          privacy is important, and that's why I'm providing you this privacy
          policy that explains how your information is collected and used here.
        </p>
        <p>
          <b>Notice:</b> allyoucanrun is a service that uses Strava's public
          APIs, but is not affiliated with Strava Inc. or the owner of
          Strava.com. If needed, please see this link for the full
          <a href="https://www.strava.com/legal/privacy" target="_blank"
            >Strava privacy policy.</a
          >
        </p>

        <h3>1. How allyoucanrun retrieves your data</h3>
        <p>
          allyoucanrun retrieves its users' data exclusively through the Strava
          APIs: this means that every time you use allyoucanrun and connect to
          your Strava account, you agree to send some information that
          allyoucanrun needs to create the analysis.
        </p>

        <h3>2. What data is used by allyoucanrun?</h3>
        <p>
          allyoucanrun can only use data made available by the Strava APIs, such
          as: Strava username, your profile picture, the list and details of
          your activities that you have uploaded to Strava. Please refer to the
          official
          <a href="https://developers.strava.com/docs/reference/"
            >Strava API documentation</a
          >
          for a detailed list of all the data that allyoucanrun can access.
        </p>

        <h3>3. How your data is used by allyoucanrun</h3>
        <p>
          The information described above is used exclusively for the functions
          offered by allyoucanrun and will not be shared in any way with third
          parties in for commercial purposes.
        </p>

        <h3>4. How your data are stored on allyoucanrun</h3>
        <p>
          All retrieved data and settings are stored on your computer in the
          browser's
          <a href="https://en.wikipedia.org/wiki/Web_storage" target="_blank"
            >WebStorage</a
          >. <br />No data are stored at an external location or passed on to
          third parties.
        </p>

        <h3>5. How you can delete your data</h3>
        <p>
          Click on your user name at the top of the page and select Log out. All
          data will then be deleted from your browser.
        </p>

        <h3>Contact:</h3>
        <p>
          Christian Siedler<br />
          Speckweg 12<br />
          85051 Ingolstadt<br />
          E-Mail: hello@allyoucanrun.com
        </p>
      </div>
    </template>
  </BaseLayout>
</template>

<script>
import BaseLayout from "@/layouts/BaseLayout.vue";
export default {
  name: "ImprintPage",
  components: {
    BaseLayout,
  },
};
</script>

<style scoped></style>
