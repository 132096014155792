export default {
  install: (app) => {
    app.config.globalProperties.$metersPerSecondToMinutesPerKilometer = (
      metersPerSecond
    ) => {
      if (metersPerSecond === Number(0)) return 0;

      const speedInKmPerHour = Number(metersPerSecond) * 3.6;
      const paceInMinPerKm = 60 / speedInKmPerHour;

      // Extract minutes and seconds
      let minutes = Math.floor(paceInMinPerKm);
      let seconds = Math.round((paceInMinPerKm - minutes) * 60);

      // TODO: geht das nicht schöner?
      if (seconds === 60) {
        minutes = minutes + 1;
        seconds = "00";
      } else if (seconds < 10) {
        seconds = `0${seconds}`;
      }

      // Format and return the result
      return `${minutes}:${seconds}`;
    };
  },
};
