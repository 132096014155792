<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: "App",
  data() {
    return {};
  },
  created() {
    this.$store.dispatch("getURLParams");
  },
  computed: {
    gear() {
      return this.$store.getters.getGear;
    },
    loading() {
      return this.$store.getters.isLoading;
    },
  },
  watch: {
    gear: {
      handler() {
        if (this.gear) {
          this.$store.dispatch("storeGearInLocalStorage");
        }
      },
      immediate: false,
      deep: true,
    },
    loading: {
      handler() {
        if (this.loading) {
          // console.log("set isloading");
          document.querySelector("body").classList.add("is-loading");
        } else {
          // console.log("remove isloading");
          document.querySelector("body").classList.remove("is-loading");
        }
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>

<style lang="scss"></style>
