<template>
  <footer class="footer">
    <p class="text-center d-flex justify-center align-center">
      &copy; 2024 allyoucanrun.com |
      <img
        src="@/assets/images/api_logo_pwrdBy_strava_horiz_white.svg"
        width="180"
        class="d-inline-block"
        alt="Powered by Strava"
      />
    </p>
    <p class="text-center">
      <router-link to="/imprint">Privacy Police / Contact</router-link>
    </p>
  </footer>
  <div class="loader">
    <div class="loader-animation"></div>
    <div class="loader-message">
      <h4>{{ loadingMessage }}</h4>
    </div>
  </div>
</template>

<script>
export default {
  name: "TheFooter",
  data() {
    return {};
  },
  computed: {
    loadingMessage() {
      return this.$store.getters.getLoadingMessage;
    },
  },
};
</script>

<style scoped></style>
