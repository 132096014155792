<template>
  <span class="icon" :class="classNames" aria-hidden="true">
    <component :is="iconName"></component>
  </span>
</template>

<script>
import { defineAsyncComponent } from "vue";
/* use: <IconComponent :icon="'sortIcon'" :class="'icon-light'" /> */
/* you will find the Icons in ./components/icons/..  - iconName = filename */
export default {
  name: "IconComponent",
  data() {
    return {
      currentIcon: null,
    };
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
    class: {
      type: String,
      required: false,
      default: "",
    },
  },
  computed: {
    classNames() {
      return this.class;
    },
    iconName() {
      return defineAsyncComponent(() => import(`./icons/${this.icon}.vue`));
    },
  },
};
</script>

<style></style>
