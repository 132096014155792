<template>
  <EmptyLayout>
    <template #default>
      <h2>Page not found</h2>
    </template>
  </EmptyLayout>
</template>

<script>
import EmptyLayout from "@/layouts/EmptyLayout.vue";

export default {
  name: "NotFoundPage",
  components: {
    EmptyLayout,
  },
};
</script>

<style scoped></style>
