<template>
  <header class="header">
    <nav>
      <ul class="nav">
        <li class="nav-item nav-brand">
          <router-link to="/shoesoverview" class="nav-link"
            ><h1 class="text-brand">allyoucanrun</h1>
            <i class="font-size-200">beta</i></router-link
          >
        </li>
      </ul>
    </nav>
    <div class="header-user" v-if="isUserLoggedIn">
      <div tabindex="0" class="header-user-name">
        {{ userName }}
      </div>
      <span class="header-user-imager-round"
        ><img :src="userImage" :alt="userName" class="header-user-image"
      /></span>

      <ul class="header-user-menu">
        <li class="header-user-menu-item">
          <button class="btn btn-text" type="button" @click="logout()">
            Log Out
          </button>
        </li>
      </ul>
    </div>
  </header>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "TheHeader",
  computed: {
    ...mapGetters(["isUserLoggedIn", "userName", "userImage"]),
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
      this.$store.commit("removeGear");
      this.$router.push({ path: "/" });
    },
  },
};
</script>

<style scoped></style>
