import "@/assets/js/script";
import { createApp } from "vue";
import store from "./store/";
import router from "./router";
import calcSpeed from "./plugins/calcSpeed";
import IconComponent from "./components/IconComponent";
import App from "./App.vue";
import "@/assets/scss/_styles.scss";

const app = createApp(App);

app.component("IconComponent", IconComponent);
app.use(calcSpeed);
app.use(store);
app.use(router);
app.mount("#app");
