<template>
  <div role="table" class="grid-table">
    <div class="grid-table-header">
      <div role="row" class="grid-table-tr">
        <div class="col-nr">#</div>
        <div role="columnheader" class="col-name">Shoe</div>
        <div role="columnheader" class="col-distance">Total km</div>
        <div role="columnheader" class="col-pace">Avg. Pace - min/km</div>
        <div role="columnheader" class="col-cadence">
          Avg. Cadence - steps/min
        </div>
        <div role="columnheader" class="col-stride">Avg. Stride Length - m</div>
        <div role="columnheader" class="col-count">Runs included</div>
      </div>
    </div>
    <div class="grid-table-body">
      <template v-for="(shoe, index) in shoes" :key="shoe.id">
        <ShoeListItem
          v-if="shoe.activitiesCount"
          :shoe="shoe"
          :index="index"
          @filter-runs="$emit('filter-runs')"
        />
      </template>
    </div>
  </div>
</template>

<script>
import ShoeListItem from "./ShoeListItem";
export default {
  name: "ShoeList",
  components: {
    ShoeListItem,
  },
  props: {
    shoes: {
      type: Object,
    },
  },
  mounted() {
    //TODO: Just listen when the user open the activity list? And remove after closing.

    // Create a new MutationObserver
    const observer = new MutationObserver((mutationsList) => {
      // Iterate through each mutation
      mutationsList.forEach((mutation) => {
        const target =
          mutation.type === "characterData"
            ? mutation.target.parentNode
            : mutation.target;
        // Check if the mutation type is 'characterData' (indicating a change in text node)

        target.classList.contains("will-animated")
          ? target.classList.add("highlight-change")
          : "";
      });
    });

    // Define the node to observe
    const targetNode = document.querySelector(".grid-table-body");

    // Options for the observer
    const config = {
      characterData: true,
      childList: true,
      subtree: true,
      attributes: false,
    };

    // Start observing the target node for changes
    observer.observe(targetNode, config);

    // To disconnect the observer when you're done observing:
    // observer.disconnect();

    //  make this global to use this logic on the whole website?
    const willAnimated = document.querySelectorAll(".will-animated");
    willAnimated.forEach((elem) => {
      elem.addEventListener("animationend", () => {
        elem.classList.remove("highlight-change");
      });
    });
  },
};
</script>

<style lang="scss"></style>
