<template>
  <div role="row" class="grid-table-tr">
    <div role="column" class="col-nr"></div>
    <div role="column" class="col-name">{{ shoe.name }}</div>
    <div role="column" class="col-distance">{{ shoe.converted_distance }}</div>
    <div role="column" class="col-pace will-animated">
      {{ averagePace }}
    </div>
    <div role="column" class="col-cadence will-animated">
      {{ shoe.average_cadence_overall }}
    </div>
    <div role="column" class="col-stride will-animated">
      {{ shoe.average_stride_length_overall }}
    </div>
    <div role="column" class="col-count will-animated">
      {{ activitiesCount }}
      <button
        @click="showActivities($event)"
        class="btn btn-icon btn-image"
        aria-label="View all runs with this shoe"
        title="View all runs with this shoe"
      >
        <IconComponent :icon="'arrowDropDownIcon'" />
      </button>
    </div>

    <div role="column" aria-hidden="true" class="col-activties">
      <ActivitiesList
        v-if="showActivitiesList"
        :activities="shoe.activities"
        :shoeName="shoe.name"
        @filter-runs="$emit('filter-runs')"
      ></ActivitiesList>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";

export default {
  components: {
    ActivitiesList: defineAsyncComponent(() => {
      return import("./ActivitiesList");
    }),
  },
  name: "ShoeIListItem",
  data() {
    return {
      showActivitiesList: false,
    };
  },
  props: {
    shoe: {
      type: Object,
    },
    index: {
      type: Number,
    },
  },
  methods: {
    showActivities(event) {
      const elem = event.currentTarget;
      const row = elem.closest(".grid-table-tr");
      row.classList.toggle("show-activities");

      this.showActivitiesList = true;
    },
  },
  computed: {
    shoeDistance() {
      return Math.floor(this.shoe.converted_distance / 1000);
    },
    averagePace() {
      return this.$metersPerSecondToMinutesPerKilometer(
        this.shoe.average_speed_overall
      );
    },
    activitiesCount() {
      if (this.shoe.activities.length === this.shoe.activitiesCount) {
        return this.shoe.activitiesCount;
      } else {
        return this.shoe.activitiesCount + "/" + this.shoe.activities.length;
      }
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped></style>
