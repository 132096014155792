<template>
  <div class="wrapper">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "EmptyLayout",
};
</script>

<style scoped></style>
