import LoginPage from "@/pages/LoginPage";
import NotFoundPage from "@/pages/NotFoundPage";
import ShoesOverview from "@/pages/ShoesOverview";
import ImprintPage from "@/pages/ImprintPage.vue";
import store from "@/store";

import { createRouter, createWebHistory } from "vue-router";

const router = createRouter({
  // Optionen
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      component: LoginPage,
      name: "LoginPage",
    },
    {
      path: "/shoesoverview",
      component: ShoesOverview,
      name: "ShoesOverview",
    },
    {
      path: "/imprint",
      component: ImprintPage,
      name: "imprint",
    },
    {
      path: "/:pathMatch(.*)*",
      component: NotFoundPage,
      /* redirect: "/", */
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (!store.getters.isUserLoggedIn && to.name == "ShoesOverview") {
    next({ name: "LoginPage" });
  } else {
    next();
  }
});

export default router;
