<template>
  <BaseLayout>
    <template #default>
      <div class="text-center col-50 col-center">
        <h3>
          Connect your Strava account and compare the data from your running
          shoes.<br />
          Which shoe is the fastest, in which do you have the highest cadence
          and stride length?
        </h3>
        <button
          @click="auth()"
          type="button"
          class="btn btn-image btn-connect mb-32"
          aria-label="Log in and Connect with Strava"
        >
          <img
            src="@/assets/images/btn_strava_connectwith_orange.svg"
            width="300"
            alt=""
          />
        </button>
        <p>
          This tool is still in the beta phase, so the functionality is not
          final yet. If you found any bugs, have questions or ideas, please send
          me an e-mail: hello@allyoucanrun.com
        </p>
        <p class="text-center privacy-data-notice">
          All data will stored locally on your computer. <br />
          For more Information read the
          <router-link to="/imprint">Privacy Police</router-link>
        </p>
      </div>
    </template>
  </BaseLayout>
</template>

<script>
import BaseLayout from "@/layouts/BaseLayout.vue";

export default {
  name: "LoginPage",
  components: {
    BaseLayout,
  },
  methods: {
    auth() {
      const currentURL = window.location.origin;
      window.location = `https://www.strava.com/oauth/authorize?client_id=${this.$store.state.auth.app.clientid}&redirect_uri=${currentURL}&response_type=code&scope=read_all,activity:read_all,profile:read_all&state=response`;
    },
  },
};
</script>

<style></style>
